.logo {
  width: 212px;
  height: 300px;
}

.loginFields {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.loginButton {
  width: 100%;
  margin-top: 50px;
}
