@font-face {
  font-family: 'NeueHaasDisplayRoman';
  src: local('NeueHaasDisplayRoman'), url(../fonts/NeueHaasDisplayRoman.ttf) format('truetype');
}

@font-face {
  font-family: 'NeueHaasDisplayBold';
  src: local('NeueHaasDisplayBold'), url(../fonts/NeueHaasDisplayBold.ttf) format('truetype');
}

@font-face {
  font-family: 'IvyMode-LightItalic';
  src: local('IvyMode-LightItalic'), url(../fonts/IvyMode-LightItalic.ttf) format('truetype');
}

a:link {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}
/* visited link */
a:visited {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}
/* mouse over link */
a:hover {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}
/* selected link */
a:active {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}
.QRCode {
  cursor: pointer;
}

.Gradient {
  background: linear-gradient(rgba(0, 0, 0, 0.3), transparent);
}
.GradientA {
  background: linear-gradient(rgba(0, 0, 0, 0.0), transparent);
  z-index: 100;
}

.Tag1 {
  font-family: 'NeueHaasDisplayRoman';
  font-size: 12px;
  font-weight: 800;
  color: rgba(255, 255, 255, 1.0)
}
.Tag1a {
  font-family: 'NeueHaasDisplayRoman';
  font-size: 12px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1.0)
}

.Tag2 {
  font-family: 'IvyMode-LightItalic';
  font-size: 10px;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 5px;
  color: rgba(255, 255, 255, 1.0)
}
.Tag2a {
  font-family: 'IvyMode-LightItalic';
  font-size: 10px;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 5px;
  color: rgba(0, 0, 0, 1.0)
}

.DesignLine {
  z-index: 100;
}
.DesignLine2 {
  z-index: 101;
}

.Header1 {
  font-family: 'NeueHaasDisplayBold';
  font-size: 17px;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.7);
  white-space: pre-line;
}

.Header2 {
  font-family: 'NeueHaasDisplayRoman';
  font-size: 11px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  white-space: pre-line;
}

.Download {
  font-family: 'NeueHaasDisplayBold';
  font-size: 10px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.DetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(255, 255, 255, 1.0)
}

.IconsContainer {
  width: 26px;
  height: 26px;
  border-radius: 26px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1.0)
}

.Details {
  font-family: 'NeueHaasDisplayRoman';
  font-size: 10px;
  font-weight: 400;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.7);
  display: block;
  white-space: pre-line;
}
